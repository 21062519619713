import { Box, Grid2, useTheme } from '@mui/material';
import { PropsWithChildren, useEffect, useState } from 'react';
import { ResponsiveStyleValue } from '@althera/website/helpers/typeUtilities';
import { useMediaHooks } from '@althera/website/hooks/useMediaHooks';
import { useStyles } from './index.style';

interface BlockProps {
  rowOffset?: ResponsiveStyleValue<number>;
  colOffset?: ResponsiveStyleValue<number>;
}

export const GridSpacer = (props: PropsWithChildren<BlockProps>) => {
  const { children, rowOffset, colOffset } = props;
  const { spacing, gutter } = useTheme();
  const { classes } = useStyles();
  const { isDesktop, isMobile, isTablet } = useMediaHooks();
  const [hide, setHide] = useState(true);

  const rowOffsetDesktop = rowOffset?.desktop ?? 0;
  const rowOffsetTablet = rowOffset?.tablet ?? 0;
  const rowOffsetMobile = rowOffset?.mobile ?? 0;

  const colOffsetDesktop = colOffset?.desktop ?? 0;
  const colOffsetTablet = colOffset?.tablet ?? 0;
  const colOffsetMobile = colOffset?.mobile ?? 0;

  useEffect(() => {
    // If there are children, don't hide
    if (children) {
      setHide(false);
      return;
    }

    // If there are no children and is desktop viewport, hide if the rowOffset and colOffset are 0
    if (isDesktop) {
      setHide(isDesktop && rowOffsetDesktop === 0 && colOffsetDesktop === 0);
      return;
    }

    // If there are no children and is tablet viewport, hide if the rowOffset and colOffset are 0
    if (isTablet) {
      setHide(isTablet && rowOffsetTablet === 0 && colOffsetTablet === 0);
      return;
    }

    // If there are no children and is mobile viewport, hide if the rowOffset and colOffset are 0
    setHide(isMobile && rowOffsetMobile === 0 && colOffsetMobile === 0);
  }, [children, colOffsetDesktop, colOffsetMobile, colOffsetTablet, isDesktop, isMobile, isTablet, rowOffsetDesktop, rowOffsetMobile, rowOffsetTablet]);

  return (
    <Box className={classes.root} sx={{ display: hide ? 'none' : undefined }}>
      <Grid2
        sx={{ paddingTop: { desktop: spacing(3 * rowOffsetDesktop), tablet: spacing(3 * rowOffsetTablet), mobile: spacing(2 * rowOffsetMobile) } }}
        container
        columns={{ desktop: 12, tablet: 8, mobile: 4 }}
        rowSpacing={{ desktop: gutter.xxs, tablet: gutter.xs, mobile: gutter.xxs }}
        columnSpacing={{ desktop: gutter.lg, tablet: gutter.lg, mobile: gutter.xs }}>
        <Grid2 sx={{ lineHeight: 0 }} size={12} offset={{ desktop: colOffsetDesktop, tablet: colOffsetTablet, mobile: colOffsetMobile }}>
          {children}&nbsp;
        </Grid2>
      </Grid2>
    </Box>
  );
};
