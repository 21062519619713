import { Stack } from '@mui/material';
import { Scroller } from '@althera/website/layout/scroller';
import { Footer } from './footer';
import { Header } from './header';
import { useStyles } from './index.style';
import { Main } from './main';

export const Layout = () => {
  const { classes } = useStyles();

  return (
    <Stack className={classes.root} direction="column">
      <Scroller />
      <Header />
      <Main />
      <Footer />
    </Stack>
  );
};
