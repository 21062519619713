import { KeyboardArrowDown } from '@mui/icons-material';
import { Collapse, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Fragment, MouseEvent, useCallback, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useMediaHooks } from '@althera/website/hooks/useMediaHooks';
import { links } from '@althera/website/layout/helper';
import { useStyles } from './linksList.style';

interface LinksListProps {
  onNavigate: () => void;
}

export const LinksList = (props: LinksListProps) => {
  const { onNavigate } = props;
  const { classes, cx } = useStyles();
  const { hash } = useLocation();
  const { isTablet } = useMediaHooks();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [selectedMenu, setSelectedMenu] = useState<string>();

  const handleMenuToggle = useCallback((e: MouseEvent<HTMLDivElement>, label: string, currentLabel?: string) => {
    e.stopPropagation();
    e.preventDefault();

    const button = e.currentTarget.parentElement;
    if (!button) return;

    if (currentLabel === label) {
      setSelectedMenu(undefined);
      setAnchorEl(null);
      return;
    }

    setSelectedMenu(label);
    setAnchorEl(button);
  }, []);

  const handleCloseSubmenu = useCallback(() => {
    onNavigate();
  }, [onNavigate]);

  console.log(isTablet);

  return (
    <List className={classes.root} component="nav" disablePadding>
      {links.map(({ to, label, subLinks }) => {
        const isOpen = Boolean(anchorEl) && selectedMenu === label;

        return (
          <Fragment key={`fragmentListItem-${label}`}>
            <ListItem
              key={`listItem-${label}`}
              className={cx(classes.mainListItem, {
                [classes.mainListItemOpen]: isOpen
              })}
              component={NavLink}
              divider
              disableGutters={isTablet}
              unstable_viewTransition
              tabIndex={0}
              onClick={handleCloseSubmenu}
              to={to}>
              <ListItemText
                className={classes.listItemText}
                primaryTypographyProps={{
                  component: 'span',
                  variant: isTablet ? 'navLinkBig' : 'navLinkSmall',
                  color: 'inherit'
                }}>
                {label}
              </ListItemText>
              {subLinks && subLinks.length > 0 && (
                <ListItemIcon className={cx(classes.listItemIcon, { [classes.listItemIconOpen]: isOpen })} onClick={e => handleMenuToggle(e, label, selectedMenu)}>
                  <KeyboardArrowDown fontSize={isTablet ? 'large' : 'medium'} />
                </ListItemIcon>
              )}
            </ListItem>
            {subLinks && subLinks.length > 0 && (
              <Collapse in={isOpen} unmountOnExit>
                <List className={classes.subMenuList} component="nav">
                  {subLinks.map(subLink => (
                    <ListItem key={subLink.label} component={Link} disablePadding to={`${to}#${subLink.to}`} disableGutters={isTablet} onClick={onNavigate}>
                      <ListItemText
                        className={classes.listItemText}
                        primary={subLink.label}
                        primaryTypographyProps={{
                          variant: 'subheadingSmall',
                          component: 'span',
                          color: `#${subLink.to}` === hash ? 'secondary' : 'textPrimary'
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            )}
          </Fragment>
        );
      })}
    </List>
  );
};
