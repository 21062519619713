import { MenuOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { MouseEvent, useCallback, useState } from 'react';
import { useMediaHooks } from '@althera/website/hooks/useMediaHooks';
import { Mobile } from './mobile';
import { Tablet } from './tablet';

export const ResponsiveNav = () => {
  const { isMobile, isTablet, isDesktop } = useMediaHooks();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleMenuOpen = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => {
    window.scrollTo(0, 0);
    setAnchorEl(null);
  }, []);

  if (isDesktop) {
    return null;
  }

  return (
    <>
      <IconButton size="large" edge={isTablet ? 'end' : 'start'} onClick={handleMenuOpen}>
        <MenuOutlined />
      </IconButton>
      <Mobile open={Boolean(isMobile && anchorEl)} onClose={handleMenuClose} />
      <Tablet open={Boolean(isTablet && anchorEl)} onClose={handleMenuClose} anchorEl={anchorEl?.closest('header') ?? null} />
    </>
  );
};
