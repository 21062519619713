import { KeyboardArrowDown } from '@mui/icons-material';
import { IconButton, Link, Menu, MenuItem, Stack, Typography } from '@mui/material';
import { MouseEvent, useCallback, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { links } from '@althera/website/layout/helper';
import { useStyles } from './index.style';

export const DesktopNav = () => {
  const { classes, cx } = useStyles();
  const { hash: locationHash } = useLocation();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [menuId, setMenuId] = useState<string>();
  const open = Boolean(anchorEl);

  const handleMenuOpen = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();

    const button = e.currentTarget.parentElement;
    if (!button) return;

    setMenuId(e.currentTarget.id);
    setAnchorEl(button);
  }, []);

  const handleMenuClose = () => {
    setMenuId(undefined);
    setAnchorEl(null);
  };

  return (
    <Stack className={classes.root} component="nav" direction="row" columnGap={4}>
      {links.map(({ id, to, label, subLinks }) => (
        <>
          <Typography key={`${id}-label`} className={classes.navLink} component={NavLink} tabIndex={0} preventScrollReset unstable_viewTransition to={to} variant="cta">
            {label}
            {subLinks && (
              <IconButton
                id={id}
                aria-controls={`${id}-menu`}
                aria-haspopup="true"
                aria-expanded={menuId === id && open ? 'true' : undefined}
                onClick={handleMenuOpen}
                size="small">
                <KeyboardArrowDown className={cx(classes.navLinkIcon, { [classes.navLinkIconClicked]: menuId === id && open })} />
              </IconButton>
            )}
          </Typography>
          {subLinks && (
            <Menu
              key={`${id}-menu`}
              id={`${id}-menu`}
              open={menuId === id && open}
              onClose={handleMenuClose}
              disablePortal
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              component="nav"
              MenuListProps={{
                'aria-labelledby': id
              }}>
              {subLinks?.map(({ to: hash, label }) => (
                <MenuItem key={`${id}-${label}`} component={Link} href={`${to}#${hash}`} onClick={handleMenuClose} underline="none" selected={`#${hash}` === locationHash}>
                  {label}
                </MenuItem>
              ))}
            </Menu>
          )}
        </>
      ))}
    </Stack>
  );
};
