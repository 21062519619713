import { AppBar, Box, Container, List, ListItem, ListItemText, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { GridSpacer } from '@althera/website/components/gridSpacer';
import { Logo } from '@althera/website/components/logo';
import { useMediaHooks } from '@althera/website/hooks/useMediaHooks';
import { links } from '@althera/website/layout/helper';
import { useStyles } from './index.style';

export const Footer = () => {
  const { classes } = useStyles();
  const { isMobile } = useMediaHooks();

  return (
    <AppBar className={classes.root} component="footer" position="static" variant="outlined" color="transparent">
      <Container>
        <GridSpacer rowOffset={{ desktop: 3, tablet: 1.5, mobile: 1.25 }}>
          <Box className={classes.appbar}>
            <Box className={classes.logo}>
              <Logo />
            </Box>
            <Box className={classes.menus}>
              {links
                .filter(link => link.label !== 'Home')
                .map(link => (
                  <List key={link.to} className={classes.menu}>
                    <ListItem className={classes.mainMenuItem} component={Link} to={link.to}>
                      <ListItemText
                        primaryTypographyProps={{ variant: isMobile ? 'subheadingSmall' : 'subheadingMedium', color: 'primary.contrastText', fontWeight: 'fontWeightBold' }}>
                        {link.label}
                      </ListItemText>
                    </ListItem>
                    {!isMobile &&
                      link.subLinks?.map(subLink => (
                        <ListItem key={subLink.to} component={Link} to={`${link.to}#${subLink.to}`} dense>
                          <ListItemText primaryTypographyProps={{ variant: 'subheadingSmall', color: 'primary.contrastText' }}>{subLink.label}</ListItemText>
                        </ListItem>
                      ))}
                  </List>
                ))}
            </Box>
            <Box className={classes.copyright}>
              <Typography className={classes.copyrightText} variant="subheadingSmall" color="primary.contrastText">
                ©{new Date().getFullYear()} Althera. All rights reserved.
              </Typography>
            </Box>
          </Box>
        </GridSpacer>
        <GridSpacer rowOffset={{ desktop: 3, tablet: 3, mobile: 3 }} />
      </Container>
    </AppBar>
  );
};
