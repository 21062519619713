import { Close } from '@mui/icons-material';
import { Drawer, IconButton, Stack } from '@mui/material';
import { Logo } from '@althera/website/components/logo';
import { LinksList } from '@althera/website/layout/header/components/linksList';
import { useStyles } from './index.style';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const Mobile = (props: Props) => {
  const { open, onClose } = props;
  const { classes } = useStyles();

  return (
    <Drawer
      classes={{ paper: classes.drawerPaper }}
      open={open}
      onClose={onClose}
      ModalProps={{
        hideBackdrop: true
      }}>
      <Stack className={classes.header} direction="row" justifyContent="space-between" alignItems="center">
        <Logo />
        <IconButton edge="end" onClick={onClose}>
          <Close />
        </IconButton>
      </Stack>
      <LinksList onNavigate={onClose} />
    </Drawer>
  );
};
