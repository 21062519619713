import { tss } from 'tss-react/mui';
import heart from '@althera/website/assets/images/not-found/background.png';

export const useStyles = tss.withName('NotFoundScene').create(({ theme }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.gutter.md,
    height: '100%',
    backgroundImage: `url(${heart})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  },
  text: {
    textAlign: 'center'
  }
}));
