import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { useStyles } from './index.style';

export const Main = () => {
  const { classes } = useStyles();

  return (
    <Box className={classes.root} component="main">
      <Outlet />
    </Box>
  );
};
