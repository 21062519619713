import { tss } from 'tss-react/mui';

export const useStyles = tss.withName('LayoutHeaderLinksList').create(({ theme }) => ({
  root: {
    paddingBottom: theme.gutter.md
  },
  mainListItem: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row-reverse',
    color: theme.palette.text.primary,

    '&.active': {
      color: theme.palette.secondary.main
    },

    [theme.breakpoints.down('tablet')]: {
      flexDirection: 'row'
    }
  },
  mainListItemOpen: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText
  },
  subMenuList: {
    borderBottom: `1px solid ${theme.palette.divider}`,

    [theme.breakpoints.down('tablet')]: {
      paddingLeft: theme.gutter.md
    }
  },
  listItemText: {
    paddingRight: theme.spacing(10),
    textAlign: 'right',

    [theme.breakpoints.down('tablet')]: {
      paddingRight: 0,
      textAlign: 'left'
    }
  },
  listItemIcon: {
    willChange: 'transform',
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.short
    })
  },
  listItemIconOpen: {
    transform: 'rotate(180deg)',
    color: theme.palette.info.contrastText
  }
}));
