import { AppBar, Container, Stack } from '@mui/material';
import { GridSpacer } from '@althera/website/components/gridSpacer';
import { Logo } from '@althera/website/components/logo';
import { DesktopNav } from './components/desktopNav';
import { ResponsiveNav } from './components/responsiveNav';
import { useStyles } from './index.style';

export const Header = () => {
  const { classes } = useStyles();

  return (
    <AppBar id="layout-header" component="header" position="sticky" color="inherit">
      <GridSpacer rowOffset={{ desktop: 1, tablet: 1, mobile: 1 }}>
        <Container>
          <Stack className={classes.appbar}>
            <Logo />
            <DesktopNav />
            <ResponsiveNav />
          </Stack>
        </Container>
      </GridSpacer>
      <GridSpacer rowOffset={{ desktop: 1, tablet: 1, mobile: 1 }} />
    </AppBar>
  );
};
