import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const Scroller = () => {
  const { hash, pathname } = useLocation();

  useEffect(() => {
    let scrollIntoViewRAF: number;

    const elementRAF = requestAnimationFrame(() => {
      const id = hash ? hash.replace('#', '') : 'scroller';
      const element = document.getElementById(id);

      scrollIntoViewRAF = requestAnimationFrame(() => {
        element?.scrollIntoView({ behavior: 'smooth' });
      });
    });

    return () => {
      cancelAnimationFrame(elementRAF);
      cancelAnimationFrame(scrollIntoViewRAF);
    };
  }, [hash, pathname]);

  return <Box id="scroller" />;
};
