import { ClickAwayListener, Menu } from '@mui/material';
import { LinksList } from '@althera/website/layout/header/components/linksList';
import { useStyles } from './index.style';

interface Props {
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
}

export const Tablet = (props: Props) => {
  const { open, anchorEl, onClose } = props;
  const { classes } = useStyles();

  return (
    <ClickAwayListener
      onClickAway={() => {
        onClose();
      }}>
      <Menu
        open={open}
        disablePortal
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 320 }}
        onClose={onClose}
        slotProps={{
          paper: {
            classes: {
              root: classes.paperRoot
            }
          }
        }}>
        <LinksList onNavigate={onClose} />
      </Menu>
    </ClickAwayListener>
  );
};
