import { tss } from 'tss-react/mui';

export const useStyles = tss.withName('LayoutHeaderResponsiveNavMobile').create(({ theme }) => ({
  header: {
    marginBottom: theme.gutter.lg,
    padding: theme.gutter.sm
  },
  drawerPaper: {
    minWidth: theme.typography.pxToRem(300),
    borderTop: 0
  }
}));
