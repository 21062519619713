import { tss } from 'tss-react/mui';

export const useStyles = tss.withName('LayoutHeaderNavMenu').create(({ theme }) => ({
  root: {
    alignItems: 'center',
    justifyContent: 'space-between',

    [theme.containerQueries.down(theme.breakpoints.values.desktop)]: {
      display: 'none'
    }
  },
  navLink: {
    fontWeight: theme.typography.fontWeightMedium,

    // .active is added automatically via NavLink component
    '&.active': {
      textDecoration: 'underline',
      textDecorationColor: 'inherit',
      textUnderlineOffset: theme.spacing(0.5),
      color: theme.palette.secondary.main,
      fontWeight: theme.typography.fontWeightBold
    },

    '&:hover': {
      textDecoration: 'underline',
      textDecorationColor: 'inherit',
      textUnderlineOffset: theme.spacing(0.5),
      color: theme.palette.secondary.main
    }
  },
  navLinkIcon: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.short
    }),

    '.active &': {
      color: theme.palette.secondary.main
    }
  },
  navLinkIconClicked: {
    transform: 'rotate(180deg)'
  }
}));
